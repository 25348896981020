import React, { Component } from 'react';
import usuariosService from '../../services/usuarios.service';
import styled from 'styled-components';

export  default class DrawUserName extends Component {
    constructor(props) {
      super(props);
      this.state = {
        avatar: 'defecto.png',
      };
    }
    async componentDidMount() {

      let info = await usuariosService.infoUsuario(this.props.userName);
      this.setState({ avatar: info.avatar });
    }
    render() {

      return (
        <>
          <UserName>{this.props.userName}</UserName>
          <img
            src={"https://corsproxy.io/?https://rolotopia-img.ams3.digitaloceanspaces.com/avatarUser/" + this.state.avatar}
            alt="Avatar de usuario"
            onError={(e) => {
              e.target.src = 'https://corsproxy.io/?https://rolotopia-img.ams3.digitaloceanspaces.com/avatarUser/defecto.png'; // Ruta a la imagen predeterminada
            }}
          />
        </>
      );
    }
  }

  const UserName = styled.div`
  position: absolute;
  font-size: calc(20px + 5vmin);
  z-index: 1;
`;
