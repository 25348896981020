import React, { Component } from "react";

import AuthService from "../../services/auth.service";
import CampanaService from "../../services/campana.service"
import { Rnd } from "react-rnd";
import "../../css/NuevaCampana.css"

export default class MenuNuevaCampana extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enviado: false,

    };

  }


  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      enviado: true,

    });

    this.props.onPublicar(
      await CampanaService.creaCampana(
        this.state.nombre,
        AuthService.getCurrentUser().id
      ));
  }




  render() {
    return (
      <div>

        <Rnd className="menuNuevaCampana">

          <form onSubmit={this.handleSubmit}>
            <p></p>
            <h2>Título de la campaña:</h2>
            <textarea
              value={this.state.nombre}
              onChange={e => this.setState({ nombre: e.target.value })}
            />

            <p></p>
            <button type="submit">Crear campaña</button>
            <button onClick={this.props.onClose}> Cancelar</button>
          </form>

        </Rnd>
      </div>


    );
  }
}