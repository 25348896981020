import axios from "axios";
import { API_URL, BASE_URL } from '../constantes';

class CampanaService {

  lista(id) {
    return axios
      .post(API_URL + "listacampanas", {
        id
      })
      .then(response => {
        return response.data;
      });
  }

  listaPartidasCampana(idCampana) {
    return axios
      .post(API_URL + "listaPartidasCampana", {
        idCampana
      })
      .then(response => {
        return response.data;
      });
  }

  infolink(enlace) {
    console.log(enlace)
    return axios
      .post(API_URL + "infoCampana", {
        enlace
      })
      .then(response => {
        return response.data;
      });
  }

  infoCampanaId(id) {
    return axios
      .post(API_URL + "infoCampanaId", {
        id
      })
      .then(response => {
        return response.data;
      });
  }

  

  actuPortada(id, archivo) {
    return axios
      .post(API_URL + "actuPortadaCampana", {
        id,
        imagen: archivo
      });
  }

  creaCampana(nombre, idCreador) {
    return axios
      .post(API_URL + "creaCampana", {
        nombre,
        idCreador,
      })
      .then(response => {

        return response.data;
      });
  }


  cambiarTitulo(id, titulo) {
    return axios
      .post(BASE_URL + "cambiarTituloCampana", {
        id,
        titulo,
      })
      .then(response => {
        return response.data;
      });
  }

  cambiarDescripcion(id, descripcion) {
    return axios
      .post(API_URL + "cambiarDescripcionCampana", {
        id,
        descripcion,
      })
  }

  borrarCampana(idCampana) {
    return axios
      .post(API_URL + "borrarCampana", {
        idCampana,
      })
  }

}

const campanaServiceInstance = new CampanaService();
export default campanaServiceInstance;