import React from 'react';
import '../../css/App.css';
import CampanaService from "../../services/campana.service";
import Portada from "../Partidas/Portada/portada.component";
import { SPACE_URL } from '../../constantes';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import "../../css/Campana.css"

export default class MenuPartida extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campana: null,
      partidas: [],
      director: false,

    };

  }
  async componentDidMount() {
    window.history.pushState({ escena: this.state.escena }, "New Page Title", "/campaña/" + this.props.campana)
    this.setState({
      campana: await CampanaService.infolink(this.props.campana),

    });

    this.setState({
      partidas: await CampanaService.listaPartidasCampana(this.state.campana.id),
      // TODO director: await CampanaService.isDirector()


    });
    console.log(this.state.partidas)

    this.setState({
      carga: 1,
    });

  }




  render() {


    return (
      <div className="campana">
        {this.state.carga && <div>





          <div style={{ textAlign: 'center' }} className="containerPartida mt-3">


            <h1 onClick={this.onPaginaPartida} className="titulo">{this.state.campana.nombre}</h1>
            <Portada src={SPACE_URL + "/portada/" + this.state.campana.imagen} key={this.state.campana.imagen} />
            <p></p>
            {this.state.campana.descripcion !== null && ReactHtmlParser(this.state.campana.descripcion)}

            {this.state.carga &&
              <ul>

                {this.state.partidas.map((i, index) => {
                  return (
                    <li key={i.id}>
                      <Link to={"/partida/" + i.enlace} className="link">
                        <Portada src={SPACE_URL + "/portada/" + i.imagen} width={"150px"} />
                        <span>{i.nombre}</span>
                      </Link>
                    </li>

                  );
                })}
                

              </ul>

            }




          </div>



        </div>}
      </div>
    );
  }
}


